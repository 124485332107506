









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.questionInner{
  //答题题目有表格的样式重置一下
  table {
    min-height: 25px;
    line-height: 25px;
    text-align: center;
    border-collapse: collapse;
    font-size: 12px;
  }

  table,
  table tr th,
  table tr td {
    border: 1px solid #a8aeb2;
    padding: 2px 4px;
  }
}
